<template>
  <v-container>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="orcamentos"
        :loading="loading"
        :options.sync="options"
        :server-items-length="options.totalElements"
        hide-default-footer
        dense
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title> Orcamentos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark
              ><v-icon>{{ mdiPlus }}</v-icon></v-btn
            >
          </v-toolbar>
        </template>
      </v-data-table>
      <div class="text-center pt-2">
        <v-pagination
          v-model="pageable.page"
          :length="pageable.totalPages"
          @input="changePage"
        ></v-pagination>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import Orcamento from "@/service/orcamento.js";
import { mdiPlus } from "@mdi/js";
export default {
  data() {
    return {
      mdiPlus,
      orcamentos: [],
      options: {},
      pageable: {},
      headers: [
        {
          text: "Valor Orçado",
          value: "valorOrcado",
          align: "start",
          filterable: false,
          sortable: false,
        },
        {
          text: "Valor Realizado",
          value: "valorRealizado",
          filterable: false,
          sortable: false,
        },
      ],
      loading: false,
    };
  },
  methods: {
    changePage(value) {
      this.pageable.page = value;
      this.inicializar({ page: value - 1 });
    },
    async inicializar(options) {
      this.loading = true;

      const resp = await Orcamento.fetchOrcamentosProjeto({ ...options });
      console.log(resp);
      this.orcamentos = resp.content;
      this.pageable = {
        totalElements: resp.totalElements,
        totalPages: resp.totalPages,
        pageSize: resp.size,
        page: resp.number + 1,
      };

      this.loading = false;
    },
  },
  created() {
    this.inicializar();
  },
};
</script>

<style></style>
